export default function useColors() {
  return {
    text: {
      'default': 'gray.400',
      active: 'gray.50',
      hover: 'link_hovered',
    },
    bg: {
      'default': 'transparent',
      active: 'gray.800',
    },
    border: {
      'default': 'divider',
      active: 'gray.800',
    },
  };
}
