import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { transparentize } from '@chakra-ui/theme-tools';

export default function getFormStyles(props: StyleFunctionProps) {
  return {
    input: {
      empty: {
        // there is no text in the empty input
        // color: ???,
        bgColor: props.bgColor || 'black',
        borderColor: 'gray.700',
      },
      hover: {
        color: 'gray.50',
        bgColor: props.bgColor || 'black',
        borderColor: 'gray.500',
      },
      focus: {
        color: 'gray.50',
        bgColor: props.bgColor || 'black',
        borderColor: 'green.300',
      },
      filled: {
        color: 'gray.50',
        bgColor: props.bgColor || 'black',
        borderColor: 'gray.600',
      },
      readOnly: {
        color: 'gray.50',
        bgColor: 'gray.800',
        borderColor: 'gray.800',
      },
      // we use opacity to show the disabled state
      disabled: {
        opacity: 0.2,
      },
      error: {
        color: 'gray.50',
        bgColor: 'black',
        borderColor: 'red.500',
      },
    },
    placeholder: {
      'default': {
        color: 'gray.500',
      },
      disabled: {
        color: transparentize('gray.500', 0.2)(props.theme),
      },
      error: {
        color: 'red.500',
      },
    },
  };
}
