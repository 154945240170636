import { compile } from 'path-to-regexp';

import type { ResourceName, ResourcePathParams } from './resources';

export default function buildExternalUrl<R extends ResourceName>(
  resource: R,
  externalUrl: string,
  path: string,
  pathParams?: ResourcePathParams<R>,
  queryParams?: Record<
  string,
  string | Array<string> | number | boolean | null | undefined
  >,
): string {
  const url = new URL(compile(path)(pathParams), externalUrl);
  queryParams &&
    Object.entries(queryParams).forEach(([ key, value ]) => {
      value !== undefined &&
        value !== '' &&
        url.searchParams.append(key, String(value));
    });

  return url.toString();
}
