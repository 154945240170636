import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  fontSize: 'xs',
  borderRadius: 'sm',
  fontWeight: 'bold',
});

const variantSubtle = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    return {
      bg: 'whiteAlpha.100',
      color: 'whiteAlpha.800',
    };
  }

  if (c === 'gray-blue') {
    return {
      bg: 'gray.800',
      color: 'whiteAlpha.800',
    };
  }

  return {
    bg: `${ c }.800`,
    color: `${ c }.100`,
  };
});

const variants = {
  subtle: variantSubtle,
};

const Badge = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'gray',
  },
});

export default Badge;
