import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import { defineStyle, createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleTrack = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    _checked: {
      bg: `${ c }.300`,
      _hover: {
        bg: `${ c }.400`,
      },
    },
    _focusVisible: {
      boxShadow: 'none',
    },
  };
});

const baseStyle = definePartsStyle((props) => ({
  track: baseStyleTrack(props),
}));

const Switch = defineMultiStyleConfig({
  baseStyle,
});

export default Switch;
