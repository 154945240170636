import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle((props) => {
  const { emptyColor, color } = props;

  return {
    borderColor: color || 'green.300',
    borderBottomColor: emptyColor || 'whiteAlpha.200',
    borderLeftColor: emptyColor || 'whiteAlpha.200',
  };
});

const Spinner = defineStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'md',
  },
});

export default Spinner;
